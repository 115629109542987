import { useState, useRef, useEffect } from "react";
import { Button } from "@material-ui/core";
import VideoCall from "./VideoCall";
import DuoIcon from "@material-ui/icons/Duo";

function App() {
  const [inCall, setInCall] = useState(false);

  return (
    <div className="App" style={{ height: "87%" }}>
      {inCall ? (
        <VideoCall setInCall={setInCall} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <video ref={videoRef} autoPlay playsInline /> */}
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setInCall(true)}
            style={{ borderRadius: "10px", backgroundColor:"#0085FF" }}
          >
            <DuoIcon
              style={{
                marginRight: "10px",
                fontSize: "30px",
              }}
            />{" "}
            Join Video Call
          </Button>
        </div>
      )}
    </div>
  );
}

export default App;
