import { AgoraVideoPlayer } from "agora-rtc-react";
import { Grid } from "@material-ui/core";
import { useState, useEffect } from "react";

export default function Video(props) {
  const { users, tracks, small } = props;
  const [gridSpacing, setGridSpacing] = useState(12);


  const smallVid = {
    position: 'absolute',
    width: '300px',
    height: '250px',
    right: 0
  }

  const defaultVid = {
    position: 'relative'
  }

  const smallView = props.setView ? smallVid : defaultVid;

  const handleGrid = () => {
    let viewData= props.setView
    if(viewData) {
      return 12
    }
    return Math.max(Math.floor(12 / (users.length + 1)), 4)
  }


  useEffect(() => {
    setGridSpacing(handleGrid);

  }, [users, tracks, small, props.setView]);
  

  return (
    <Grid container style={{ height: "100%" }}>
      <Grid item xs={gridSpacing} style={smallView}>
        <AgoraVideoPlayer
          videoTrack={tracks[1]}
          className="wrapAgora"
          style={{ height: "100%", borderRadius: '20px' }}
        />
      </Grid>
      {users.length > 0 &&
        users.map((user, index) => {
          if (user.videoTrack) {
            return (
              <Grid item xs={gridSpacing} key={index} >
                <AgoraVideoPlayer
                  className="wrapAgora"
                  videoTrack={user.videoTrack}
                  key={user.uid}
                  style={{ height: "100%", borderRadius: '20px' }}
                />
              </Grid>
            );
          } else return null;
        })}
    </Grid>
  );
}
