import React from 'react';

export const IcoSplit = (props) => {
  return (
      <svg className={ props.className } width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Iconly/Light/Category">
        <g id="Category">
        <path id="Stroke 1" fillRule="evenodd" clipRule="evenodd" d="M4 6C4 2.2497 4.04016 1 9 1C13.9598 1 14 2.2497 14 6C14 9.7503 14.0158 11 9 11C3.98418 11 4 9.7503 4 6Z" stroke="#F6F6F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Stroke 5" fillRule="evenodd" clipRule="evenodd" d="M4 19C4 15.2497 4.04016 14 9 14C13.9598 14 14 15.2497 14 19C14 22.7503 14.0158 24 9 24C3.98418 24 4 22.7503 4 19Z" stroke="#F6F6F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
      </svg>
  );
};
